


























































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'SystemSettings',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class SystemSettings extends VueBase {
  private reflashFlag = false

  private cache = 1
  private cacheIo = false
  private cacheTime = '00:00'
  // 新功能添加
  private templateForm: any = {
    content: [],
    fid: 1,
    status: false
  }
  private isSelectAll = false
  private strategyTypeList: Array<{
    level_id: number
    level_name: number
    type_value: Array<{
      strategy_id: number
      level_id: number
      state: string
      vul_name: string
    }>
  }> = []
  
  private cacheTimeChange(date: Date) {
    console.log(date)
  }
  private async strategyTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyTypes()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    data
      .reduce(
        (
          list: any[],
          item: {
            level_id: number
            level_name: number
            type_value: Array<{
              strategy_id: number
              level_id: number
              state: string
              vul_name: string
            }>
          }
        ) => {
          list = [...list, ...item.type_value]
          return list
        },
        []
      )
      .forEach(
        (item: {
          strategy_id: number
          level_id: number
          state: string
          vul_name: string
        }) => {
          if (item.state === 'enable') {
            this.templateForm.content.push(item.strategy_id)
          }
        }
      )
    this.strategyTypeList = data
  }
  private async cleanData() {
    this.loadingStart()
    let obj = {
      clean_time: this.cacheTime,
      days_before: this.cache,
      enable: this.cacheIo,
    }
    const res = await this.services.setting.dataClean(obj)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success('变更成功')
  }
  // 查询清理配置时间
  private async getClineData() {
    this.loadingStart()
    let obj = {}
    const res = await this.services.setting.getDataClean(obj)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.cache = res.data.days_before
    this.cacheTime = res.data.clean_time
    this.cacheIo = res.data.enable
  }
  // 立即清理
  private async dataCleanTask() {
    this.loadingStart()
    let obj = {
      days_before: this.cache,
    }
    const res = await this.services.setting.dataCleanTask(obj)
    this.loadingDone()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success('清理成功')
  }
  
  private checkIdChange(id: number) {
    const idSet = new Set([...this.templateForm.content])
    if (idSet.has(id)) {
      idSet.delete(id)
    } else {
      idSet.add(id)
    }
    this.templateForm.content = [...idSet]
    this.isAllSelect()
  }
  private selectAllChange() {
    const idSet = new Set([...this.templateForm.content])
    this.strategyTypeList[
      this.strategyTypeList.findIndex(
        (i: { level_id: number }) => i.level_id === this.templateForm.fid
      )
    ].type_value.forEach(
      (item: {
        strategy_id: number
        level_id: number
        state: string
        vul_name: string
      }) => {
        if (this.isSelectAll) {
          idSet.add(item.strategy_id)
        } else {
          idSet.delete(item.strategy_id)
        }
      }
    )
    this.templateForm.content = [...idSet]
  }

  isAllSelect() {
    const idSet = new Set([...this.templateForm.content])
    this.isSelectAll = this.strategyTypeList[
      this.strategyTypeList.findIndex(
        (i: { level_id: number }) => i.level_id === this.templateForm.fid
      )
    ].type_value.every(
      (item: {
        strategy_id: number
        level_id: number
        state: string
        vul_name: string
      }) => {
        return idSet.has(item.strategy_id)
      }
    )
  }
  async cressValidGet() {
      const res = await this.services.setting.getCrossValid({});
      if(res.status === 201){
        this.templateForm.status = res.data.validation_status
        this.templateForm.content = [...res.data.strategy_id]
      } else {
        this.$message.error(res.msg)
      }
  }
  async crossValidEdit() {
    // 编辑确认
    const param = {
      strategy_id: [...this.templateForm.content],
      validation_status: this.templateForm.status
    }
    this.loadingStart()
    const res = await this.services.setting.updateCrossValid(
      param
    )
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success(res.msg)
    } else {
      this.$message.error(res.msg)
    }
  }
  private async getDocHandler(){
    let res = await this.services.setting.getDocuments({})
    if (res.status === 201) {
      window.open(res.data.url)
    } else {
      this.$message.error(res.msg)
    }
  }
  created() {
    this.getClineData()
    this.strategyTypes()
    this.cressValidGet()
  }
}
